import React from "react"
import { graphql } from "gatsby"
import Layout from "../../layouts/fr"
import ContributersPageTemplate from "../../templates/contributers-page"

const ContributorsFrench = props => {
  const { title, subtitle } = props.data.markdownRemark.frontmatter

  return (
    <Layout location={props.location} title={title} description={subtitle}>
      <ContributersPageTemplate {...props} />
    </Layout>
  )
}

export default ContributorsFrench

export const pageQuery = graphql`
  query ContributorsFrench {
    markdownRemark(
      fileAbsolutePath: { regex: "/static-pages/contributors/index.fr.md/" }
    ) {
      frontmatter {
        ...ContributorsTeamCommitteePages
      }
    }
  }
`
